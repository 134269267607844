.error {
    color: red;
    margin-left: 1em;
    font-size: .8em;
}
.success {
    color: green;
    margin-left: 1em;
}

.submit-group {
    display: flex;
}

.submit-group span {
    margin: .4em;
}

.submit-group button:disabled {
    border: 1px solid grey;
}

.thanks {
    height: 50vh;
    background: url(../../img/thanks.png) 50% 50% / 50% no-repeat;
}

/*-------------------------------------------------------------------------------------------*/

.flip-in-hor-bottom {
	-webkit-animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-5-20 3:13:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flip-in-hor-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
              transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }
  @keyframes flip-in-hor-bottom {
    0% {
      -webkit-transform: rotateX(80deg);
              transform: rotateX(80deg);
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0);
              transform: rotateX(0);
      opacity: 1;
    }
  }
  